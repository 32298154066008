<template>
  <v-theme-provider dark>
    <section id="about-description">
      <base-img
        :min-height="minHeight"
        :max-height="350"
        :gradient="gradient"
        :src="require('@/assets/about-hvs.jpg')"
        color="#45516b"
        flat
        max-width="100%"
        tile
      >
        <v-container class="fill-height px-4 py-4">
          <v-responsive
            class="d-flex align-center mx-auto"
            height="100%"
            max-width="700"
            width="100%"
          >
            <base-heading size="text-h3" title="ABOUT HVS" weight="medium" />
            <base-body>
              Hedge Vision Systems was founded in 2020 with the focus on
              accelerating the digital transformation, cloud adaptability and
              building data driven apps with data security at its core.
              <br /><br />
              We are a DPIIT recognized startup. <b>#startupindia</b>
            </base-body>
            <div class="text-center">
              <base-btn @click="showCertificate = true">
                View Certificate
              </base-btn>
            </div>
          </v-responsive>
        </v-container>
      </base-img>
      <v-dialog v-model="showCertificate" width="auto">
        <v-card>
          <div>
            <img
              v-if="$vuetify.breakpoint.mdAndUp"
              src="@/assets/Certificate.jpg"
            />
            <img v-else src="@/assets/Certificate2.jpg" />
          </div>
          <!-- <v-card-actions> -->
          <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span></button> -->
          <!-- <v-btn color="primary" @click="showCertificate = false">Close</v-btn> -->
          <!-- </v-card-actions> -->
        </v-card>
      </v-dialog>
    </section>
  </v-theme-provider>
</template>

<script>
// Components
import { HexToRGBA, RGBAtoCSS } from "vuetify/lib/util/colorUtils";

export default {
  name: "AboutDescription",

  metaInfo() {
    return {
      changed: (meta) => (this.title = meta.titleChunk.toUpperCase()),
    };
  },

  provide: {
    heading: { align: "center" },
  },

  data: () => ({
    title: "",
    showCertificate: false,
  }),

  computed: {
    gradient() {
      const color = `${this.$vuetify.theme.themes.light.secondary}CC`;
      const overlay = RGBAtoCSS(HexToRGBA(color));

      return `to top, ${overlay}, ${overlay}`;
    },
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "60vh" : "30vh";
      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    },
  },
};
</script>
